import remark from 'remark';
import remarkHtml from 'remark-html';

// ------------
// Convert Bold
// ------------
// A markdown to html converter with a substring
// on the end to remove the p tags from regular
// non heading text. Changed the name to ensure
// it is not used as improperly or accidentally

export default (input) => {
	var output = remark()
		.use(remarkHtml)
		.processSync(input)
		.toString();
	//remove p tags
	return output.substring(3, output.length - 5);
};
