import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const StyledMasthead = styled.div`
	padding: 8rem ${(p) => p.theme.gutter};
	background-image: url(${(p) => p.img});
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	h1 {
		color: #fff;
		width: 470px;
		font-weight: 700;
		@media all and (min-width: 1600px) {
			width: 750px;
		}
	}
	&.home h1 {
		margin-bottom: 3rem;
	}
	@media all and (min-width: 1200px) {
		padding: 10rem ${(p) => p.theme.gutterWide};
	}
	@media all and (min-width: 1600px) {
		padding: 12rem ${(p) => p.theme.gutterXWide};
	}
	@media all and (max-width: 580px) {
		h1 {
			width: 100%;
		}
	}
	@media all and (max-width: 500px) {
		padding: 4rem ${(p) => p.theme.gutterMobile};
	}
	@media all and (max-width: 350px) {
		h1 {
			font-size: 2.1rem;
			line-height: 2.5rem;
		}
		&.home h1 {
			margin-bottom: 2rem;
		}
	}
`;

const Masthead = ({ title, cta, img, className }) => {
	return (
		<StyledMasthead img={img} className={className}>
			<h1>{title}</h1>
			{cta && (
				<Link className="redButton" to={cta.link}>
					{cta.text}
				</Link>
			)}
		</StyledMasthead>
	);
};

export default Masthead;
